import React, { useState, useMemo, useEffect } from 'react'
import { toast } from 'react-toastify'

// Assets
import { ReactComponent as IconX } from "assets/x-black.svg"
import { ReactComponent as TrashIcon } from "assets/trash-red-line.svg"
import { ReactComponent as PencilIcon } from "assets/pencil-2.svg"

// Components
import CModal from 'components/v1/modal';
import CInput from './components/c-input';
import CSearch from './components/c-search';
import CDropdown from './components/c-dropdown';
import Button from 'components/v1/Button';

// Api
import useGetAllArea from 'api/manual/useGetAllArea';
import { useGetDetailMa } from 'api/training/query';
import { useEditCalonMa } from 'api/training/mutation';
import { useGlobalCtx } from 'contexts';
import { useTrainingCtx } from "pages/dashboard/training/useTraining"

function isTextIncluded(haystack, needle) {
  // Escape special regex characters in the needle
  const escapedNeedle = needle.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  const regex = new RegExp(escapedNeedle, 'i'); // 'i' for case-insensitive matching
  return regex.test(haystack);
}

const ModalEditCalonMa = ({ onSuccess, onDelete, userId, ...props }) => {
  const { profile } = useGlobalCtx()
  const { ListEducation } = useTrainingCtx()

  const { data: detailMa } = useGetDetailMa({ userId })

  const [isEdit, setIsEdit] = useState(false)

  const [searchArea, setSearchArea] = useState("")

  const { data: listAreaOnly, isLoading: isLoadAllArea } = useGetAllArea()

  const filteredArea = useMemo(() => {
    if (!listAreaOnly?.length) return []

    return listAreaOnly.map((area) => ({ value: area?.id, label: area?.name })).filter(l => isTextIncluded(l.label, searchArea))
  }, [listAreaOnly, searchArea])

  const [paramsEditCalonMa, setParamsEditCalonMa] = useState({
    "company_id": profile?.company?.id,
    "full_name": "",
    "card_number": "",
    "dob": "",
    "phone": "",
    "email": "",
    "area_id": "",
    "street": "",
    "last_education": ""
  })
  const { mutate: mutateEditCalonMa } = useEditCalonMa()

  const handleEditCalonMa = () => {
    mutateEditCalonMa({ id: userId, params: { user: paramsEditCalonMa } }, {
      onSuccess: () => {
        props.onRequestClose()
        onSuccess()
      },
      onError: (error) => {
        toast.error(error?.message)
      }
    })
  }

  const handleChange = (e) => {
    const { name, value } = e.target

    const newParams = { ...paramsEditCalonMa }
    newParams[name] = value

    setParamsEditCalonMa(newParams)
  }

  useEffect(() => {
    if (!!detailMa?.data?.data) {
      const newData = detailMa?.data?.data ?? {}

      setParamsEditCalonMa({ ...paramsEditCalonMa, ...newData })
    }
  }, [detailMa])

  return (
    <CModal {...props}>
      <div className='w-[426px] rounded-md'>
        <div className='py-2 px-6 flex items-center justify-between border-b border-solid border-[#D8D8D9]'>
          <span className='text-[17px] font-semibold'>{isEdit && "Ubah"} Calon Marketing Associate</span>
          <IconX onClick={props.onRequestClose} className='cursor-pointer w-[14px] h-[14px] object-contain' />
        </div>
        {!isEdit && (
          <div className='flex items-center border-y border-solid border-[#D8D8D9] py-2 px-6'>
            <Button
              icon={TrashIcon}
              text="Hapus"
              className="mr-2"
              size="xs"
              type="negative"
              onClick={() => {
                props.onRequestClose()
                onDelete()
              }}
            />
            <Button
              icon={PencilIcon}
              text="Ubah"
              size="xs"
              onClick={() => setIsEdit(true)}
              type="secondary"
            />
          </div>
        )}
        <div className='py-2 px-4'>
          <CInput
            label="Nama KTP"
            placeholder="contoh: John Doe"
            required
            onChange={handleChange}
            name="full_name"
            disabled={!isEdit}
            value={paramsEditCalonMa.full_name}
          />
          <CInput
            label="No. KTP"
            placeholder="minimal 16 digit kombinasi angka"
            required
            onChange={handleChange}
            name="card_number"
            disabled={!isEdit}
            value={paramsEditCalonMa.card_number}
          />
          <CInput
            label="Tanggal Lahir"
            placeholder="hh / bb / tttt"
            required
            onChange={handleChange}
            type="date"
            name="dob"
            disabled={!isEdit}
            value={paramsEditCalonMa.dob}
          />
          <CInput
            label="Telepon"
            placeholder="contoh: 085478560909"
            required
            name="phone"
            onChange={handleChange}
            disabled={!isEdit}
            value={paramsEditCalonMa.phone}
          />
          <CInput
            label="Alamat email"
            placeholder="contoh: johndoe@gmail.com"
            required
            onChange={handleChange}
            disabled={!isEdit}
            name="email"
            value={paramsEditCalonMa.email}
          />
          <CSearch
            label="Domisili"
            placeholder="Masukkan area"
            required
            selectedValue={paramsEditCalonMa.area_id}
            list={filteredArea}
            disabled={!isEdit}
            name="area_id"
            isLoading={isLoadAllArea}
            onSearch={(value) => setSearchArea(value)}
            onSelect={(value) => setParamsEditCalonMa({ ...paramsEditCalonMa, area_id: value })}
          />
          <CInput
            label="Alamat detail"
            placeholder="contoh: Jl. Antasari No 15D"
            required
            onChange={handleChange}
            name="street"
            value={paramsEditCalonMa.street}
            disabled={!isEdit}
          />
          <CDropdown
            label="Pendidikan terakhir"
            placeholder="Pilih jenjang pendidikan"
            required
            selectedValue={paramsEditCalonMa.last_education}
            list={ListEducation}
            name="last_education"
            disabled={!isEdit}
            onSelect={(value) => setParamsEditCalonMa({ ...paramsEditCalonMa, last_education: value })}
          />
        </div>
        {isEdit && <div className='flex items-center justify-end px-5 py-2 border-t border-solid border-[#D8D8D9] rounded-b'>
          <Button onClick={props.onRequestClose} type="secondary" text="Batal" className="mr-2" />
          <Button onClick={handleEditCalonMa} btnPrimitiveType="submit" text="Simpan" />
        </div>}
      </div>
    </CModal>
  )
}

export default function Wrapper({ isOpen, onAfterOpen, onRequestClose, ...props }) {
  if (!isOpen) return null;
  return <ModalEditCalonMa isOpen={isOpen} onAfterOpen={onAfterOpen} onRequestClose={onRequestClose} {...props} />
}